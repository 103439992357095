<template>
  <div class="c_change_date">
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }" >
      <van-datetime-picker v-model="currentDate" type="date"  title="选择出生日期" :min-date="minDate"  :max-date="maxDate" 
      :formatter="formatter"
      @confirm="pickerConfirm"
      @cancel="pickerCancel"
      />
    </van-popup>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        minDate: new Date(1970, 0, 1),
        maxDate: new Date(),
        currentDate: new Date(),
      }
    },
    methods: {
      onShow(flag, current = '') {
        if(current) {
          this.currentDate = new Date(current*1000) 
        }
        this.show = flag
      },
      pickerCancel() {
        this.onShow(false)
      },
      pickerConfirm(val) {
        this.$emit('emitDatePicker', val.getTime())
        this.onShow(false)
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }
        return `${val}日`;
      },
    }
  }
</script>

<style lang="scss">
.c_change_date {
  .van-popup {
    overflow: hidden;
  }
}
</style>