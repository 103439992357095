<template>
  <div class="c_change_address">
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }" >
      <van-area
        title="请选择地址"
        :area-list="areaList"
         :value="areaCode"
         @confirm="addressConfirm"
         @cancel="addressCancel"
      />
    </van-popup>
  </div>
</template>

<script>
  import areaList from '../utils/area.js'
  export default {
    data() {
      return {
        show: false,
        areaList,
        areaCode: '350000'
      }
    },
    methods: {
      onShow(flag, code = '350000') {
        this.show = flag
        this.areaCode = code
      },
      addressConfirm(val) {
        this.areaCode = val[val.length -1].code 
        this.$emit('emitAddress', val)
        this.onShow(false)
      },
      addressCancel() {
        this.onShow(false)
      }
    }
  }
</script>

<style lang="scss">
.c_change_address {
  .van-popup {
    overflow: hidden;
  }
}
</style>